// Implements live filtering
// Implements ARIA Live Region to announce that content on the page has updated as a result of user action
// https://www.scottohara.me/blog/2022/02/05/are-we-live.html

let filterType          = document.querySelector("#filterBuildingTypes");
let filterDevelopment   = document.querySelector("#developmentType");
let filterBedrooms      = document.querySelector("#filterBedrooms");
let filterPrices        = document.querySelector("#filterPrice");
let filterHomebuy       = document.querySelector("#filterHomebuy");

let ariaLive            = document.querySelector('#ariaLive');
let ariaMessage         = "";
let propertiesWrapper   = document.querySelector('.theProperties');

const propertyTypes = document.querySelectorAll(".theProperties > .propertyType");

let homepage        = document.querySelector('html');

if (homepage.classList.contains('homepage')) {
	let filterForm = document.querySelector('.filterForm');
	propertiesWrapper.classList.add('uc_hide-for-everyone');
	const showResults = () => {
		propertiesWrapper.classList.remove('uc_hide-for-everyone');
	}
	filterForm.addEventListener('change', showResults);
}

const doFiltering = () => {
	// console.log("---new run---");
	/*
		Explanation:
		- We build a `searchArray` which contains the parameters that the user is searching against, this is taken from the form inputs
		- We loop through each property type, and test its parameter values against the search parameters, to build a `matchArray`
		- We stringify both arrays so that we can then compare and see if they are identical - if so, the propertyType was a match for the search criteria
	*/

	propertyTypes.forEach(propertyType => {
		// hide all on first run, visually and in the a11y tree
		propertyType.classList.add('uc_hide-for-everyone');
		propertyType.setAttribute("aria-hidden", "true");
		// console.log( propertyType );

		const searchArray = []; // what is the user looking for
		const matchArray  = []; // what matches on this plot

		// build the search array and Aria message. This only adds what PROPERTY needs to be matched, and not the VALUE. Tests are done independently of this array.
		if( filterType ) {
			if( filterType.value != "any" ) { searchArray.push('building-type'); }
		}
		if( filterDevelopment ) {
			if( filterDevelopment.value != "all" ) { searchArray.push('development-type'); }
		}
		if( filterBedrooms.value != "any" ) { searchArray.push('bedrooms'); }
		if( filterPrices.value   != "any" ) { searchArray.push('prices');   }
		if( filterHomebuy.value  != "any" ) { searchArray.push('homebuy');  }
		// if( filterChildFriendly.checked )   { searchArray.push('childFriendly'); }

		// build the match array
		if( filterType ) {
			if( filterType.value == propertyType.dataset.buildingType ) {
				matchArray.push('building-type');
			}
		}
		if( filterDevelopment ) {
			if( filterDevelopment.value == propertyType.dataset.developmentType ) {
				matchArray.push('development-type');
			}
		}
		if( filterBedrooms.value == propertyType.dataset.bedrooms ) {
			matchArray.push('bedrooms');
		}
		if (filterPrices.value != "any") {
			// OK, we're looking at the prices, so lets grab the active option so we can compare
			let theSelectedOption = filterPrices.options[filterPrices.selectedIndex];

			if (
				(propertyType.dataset.price >= theSelectedOption.dataset.minPrice)
				&&
				(propertyType.dataset.price < theSelectedOption.dataset.maxPrice)
			) {
				matchArray.push('prices'); // this property is a match for the selected option
			}
		}
		if( propertyType.dataset.schemes?.split(',').includes(filterHomebuy.value) ) {
			matchArray.push('homebuy');
		}
		// if( filterChildFriendly.checked && cottage.dataset.childFriendly == 'true' ) {
		// 	matchArray.push('childFriendly');
		// }

		// if the searchArray is the same as the matchArray then the cottage was a complete match on all the searched items and should be shown
		if( JSON.stringify(searchArray) == JSON.stringify(matchArray) ) {
			propertyType.classList.remove('uc_hide-for-everyone');
			propertyType.setAttribute("aria-hidden", "false");
		} else {
			propertyType.classList.add('uc_hide-for-everyone');
			propertyType.setAttribute("aria-hidden", "true");
		}

		// console.log( "searchArray", JSON.stringify(searchArray) );
		// console.log( "matchArray", JSON.stringify(matchArray) );
	});

	let numberMatchedPropertyTypes = document.querySelectorAll('.theProperties > .propertyType:not(.uc_hide-for-everyone)').length;
	ariaLive.innerHTML = ""; // empty any old content
	ariaMessage = `List of property types updated, there ${ numberMatchedPropertyTypes == 1 ? 'is' : 'are' } ${numberMatchedPropertyTypes} match${ (numberMatchedPropertyTypes != 1) ? 'es' : '' } for property types that: `;

	if (filterType) {
		if (filterType.value != "any") {
			ariaMessage += `are a ${filterType.value}, `;
		}
		else {
			ariaMessage += `are any type of building, `;
		}
	}

	if (filterDevelopment) {
		if (filterDevelopment.value != "all") {
			ariaMessage += `are in the ${filterDevelopment.value} development, `;
		}
		else {
			ariaMessage += `are in any development, `;
		}
	}

	if( filterBedrooms.value != "any" ) {
		ariaMessage += `with ${filterBedrooms.value} bedrooms, `;
	} else { ariaMessage += `with any number of bedrooms, `; }

	if( filterPrices.value != "any" ) {
		let ukPrice = new Intl.NumberFormat(`en-GB`, {
			currency: `GBP`,
			style: `currency`,
			trailingZeroDisplay: `stripIfInteger`
		}).format( filterPrices.value );

		ariaMessage += `costing more than ${ukPrice}, `;
	} else { ariaMessage += `at any price, `; }

	if( filterHomebuy.value != "any" ) {
		ariaMessage += `with the ${ filterHomebuy.value } scheme available.`;
	} else { ariaMessage += `with any (or no) homebuy assistance scheme.`; }

	// if( filterSleeps.value != "any" ) {
	// 	ariaMessage += `sleeping ${filterSleeps.value} people, `;
	// } else { ariaMessage += `sleeping any number of people, `; }

	// if( filterChildFriendly.checked ) {
	// 	ariaMessage += `that are child friendly, `;
	// } else {
	// 	ariaMessage += `that may or may not be child friendly, `;
	// }

	ariaLive.insertAdjacentHTML('beforeend', `<div role='none'>${ ariaMessage }</div>`);
	console.log( ariaMessage );

	// handle if no matches so it's a bit more friendly
	document.querySelector("#noMatches")?.remove();
	if( numberMatchedPropertyTypes == 0 ) {
		propertiesWrapper.insertAdjacentHTML('afterBegin', `<p id="noMatches">We don't have a property to match your criteria at this time. Please consider changing search options or <a href="#mc-embedded-subscribe-form">opt-in to our mailing list</a> to be kept informed regarding future plot releases</p>`)
	}

};

// watch for changes
	if( filterType ) {
		filterType.addEventListener('change', doFiltering);
	}
	if( filterDevelopment ) {
		filterDevelopment.addEventListener('change', doFiltering);
	}
	filterBedrooms.addEventListener('change', doFiltering);
	filterPrices.addEventListener('change', doFiltering);
	filterHomebuy.addEventListener('change', doFiltering);
	// filterChildFriendly.addEventListener('change', doFiltering);

doFiltering();

